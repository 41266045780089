import { iActionDownloadClinicalDocsByCode } from '~/domain/interfaces/redux/clinicalDocs/downloadByCode';

export function* onDownloadByCode(
  action: iActionDownloadClinicalDocsByCode,
): Generator {
  yield 10;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onDownloadByCodeSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onDownloadByCodeFailed() {}
