import React, { useState, useCallback } from 'react';

import { translator } from '~/presentation/components/i18n';
import {
  HomeCell,
  IconV4Hatendimento,
  LavidIcon,
  RnpIcon,
  UfpbIcon,
} from '~/presentation/base/icons';
import { Button, Input } from '~/presentation/components/UI';
import Linkto from '~/presentation/components/UI/link';
import { Icon } from '~/presentation/components/icon';
import { makeRemoteGetClinicalDocsByCode } from '~/main/factories/usecases/clinicalDocs/GetClinicalDocsByCode';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { GetClinicalDocsByCode } from '~/domain/usecases/clinicalDocs/remote';
import {
  Container,
  Title,
  Subtitle,
  Division,
  Section,
  Footer,
  FooterImgs,
  Image,
} from './StyledPrescription';
import { PrescriptionDetail } from '..';

const Prescription: React.FC = (): JSX.Element => {
  // MOCK testing code: t4YGz2wj
  // MOCK testing secret: B3qzCv3c
  const [code, setCode] = useState('');
  const [secret, setSecret] = useState('');
  const [clinicalDocId, setClinicalDocId] = useState<number>(0);
  const [clinicalDoc, setClinicalDoc] = useState<GetClinicalDocsByCode.Model>(
    {} as GetClinicalDocsByCode.Model
  );

  const today = new Date();

  const footerImages = [
    {
      icon: RnpIcon,
      name: 'rnp-icon',
    },
    {
      icon: UfpbIcon,
      name: 'ufpb-icon',
    },
    {
      icon: LavidIcon,
      name: 'lavid-icon',
    },
  ];

  const handleSubmit = useCallback(() => {
    if (code !== '' && secret !== '') {
      makeRemoteGetClinicalDocsByCode()
        .get({
          code,
          secret,
        })
        .then((res) => {
          setClinicalDocId(res.id);
          setClinicalDoc(res);
        })
        .catch((err) => {
          AlertMessage({
            message: 'Não foi possível encontrar o documento.',
            type: 'danger',
          });
          console.log('err: ', err);
        });
    }
  }, [code, secret]);

  return clinicalDocId ? (
    <PrescriptionDetail
      doc={clinicalDoc}
      code={code}
      secret={secret}
      handleBack={() => setClinicalDocId(0)}
    />
  ) : (
    <Container>
      <Section>
        <Division>
          <div className="cellLogo">
            <HomeCell className="cellImg" />
          </div>

          <div className="enter">
            <Icon src={IconV4Hatendimento} width="200px" height="100px" />
            <Title>Acesso a receitas</Title>
            <Subtitle>
              Insira o CÓDIGO e SENHA disponíveis na receita do paciente para
              visualizar o arquivo assinado pelo médico.
            </Subtitle>
            <Input
              className="input-code"
              height="44px"
              width="280px"
              name="code"
              label="Código"
              placeholder="Digite o código da receita"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <div style={{ marginBottom: '16px' }} />
            <Input
              className="input-secret"
              height="44px"
              width="280px"
              name="secret"
              type="password"
              label="Senha"
              placeholder="Digite a senha da receita"
              value={secret}
              onChange={(e) => setSecret(e.target.value)}
            />
            <Button
              size="small"
              variant="primary"
              style={{ width: '280px', marginTop: '16px' }}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              Acessar
            </Button>
          </div>
        </Division>
        <Footer>
          <FooterImgs>
            {footerImages.map((image) => (
              <Image alt={image.name} src={image.icon} height="70px" />
            ))}
          </FooterImgs>
          {`Copyright ${today.getFullYear()}`}
          <div className="links">
            <Linkto
              to="https://minio.v4h.cloud/public/V4H_Termos_de_Uso.pdf"
              title={translator('Termos de uso')}
            />
            e
            <Linkto
              to="https://minio.v4h.cloud/public/V4H_Poli%CC%81tica_de_Privacidade.pdf"
              title={translator('Política de privacidade')}
            />
          </div>
        </Footer>
      </Section>
    </Container>
  );
};

export default Prescription;
