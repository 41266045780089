import styled from 'styled-components';
import iconStop from '~/presentation/base/icons/stop.svg';
import iconCancel from '~/presentation/base/icons/cancel.svg';
import iconCancelOutline from '~/presentation/base/icons/cancelOutline.svg';
import iconDetails from '~/presentation/base/icons/details.svg';
import iconDetailsOutline from '~/presentation/base/icons/detailsOutline.svg';
import iconPerson from '~/presentation/base/icons/person.svg';
import iconPersonOutline from '~/presentation/base/icons/personOutline.svg';
import iconDelete from '~/presentation/base/icons/delete.svg';
import iconDeleteOutline from '~/presentation/base/icons/deleteOutline.svg';
import iconWait from '~/presentation/base/icons/wait.svg';
import iconWaitOutline from '~/presentation/base/icons/waitOutline.svg';
import iconStart from '~/presentation/base/icons/start.svg';
import iconStartOutline from '~/presentation/base/icons/startOutline.svg';
import iconMenu from '~/presentation/base/icons/menu.svg';
import iconMenuOutline from '~/presentation/base/icons/menuOutline.svg';
import iconNewDocument from '~/presentation/base/icons/newDocument.svg';
import iconNewDocumentOutline from '~/presentation/base/icons/newDocumentOutline.svg';
import iconShareEmail from '~/presentation/base/icons/shareEmail.svg';
import iconShareEmailOutline from '~/presentation/base/icons/shareEmailOutline.svg';
import iconDownload from '~/presentation/base/icons/download.svg';
import iconDownloadOutline from '~/presentation/base/icons/downloadOutline.svg';
import iconHome from '~/presentation/base/icons/home.svg';
import iconHomeOutline from '~/presentation/base/icons/homeOutline.svg';
import iconEdit from '~/presentation/base/icons/editIcon.svg';
import iconEditFill from '~/presentation/base/icons/editIconFill.svg';
import IconDashboard from '~/presentation/base/icons/icon_dashboard.svg';
import IconCopyLink from '~/presentation/base/icons/iconCopyLink.svg';
import IconCopyLinkHover from '~/presentation/base/icons/iconCopyLinkHover.svg';
import IconOptionsLinkHover from '~/presentation/base/icons/optionsLinkHover.svg';
import IconSendWhats from '~/presentation/base/icons/sendWhats.svg';
import IconHoverWhats from '~/presentation/base/icons/hoverWhats.svg';
import { defaultTheme } from '~/presentation/base/themes';

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Container = styled.div``;
export const Cancel = styled.div`
  background-image: url(${iconCancelOutline});
  margin: 0px 3px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  :hover {
    background-image: url(${iconCancel});
  }
`;

export const Details = styled.div`
  margin: 0px 3px;
  background-image: url(${iconDetailsOutline});
  width: 26px;
  height: 26px;
  cursor: pointer;
  :hover {
    background-image: url(${iconDetails});
  }
`;

export const Delete = styled.div`
  margin: 0px 3px;
  background-image: url(${iconDeleteOutline});
  width: 26px;
  height: 26px;
  cursor: pointer;
  :hover {
    background-image: url(${iconDelete});
  }
`;

export const EditIcon = styled.div`
  margin: 0px 3px;
  background-image: url(${iconEdit});
  width: 26px;
  height: 26px;
  cursor: pointer;
  :hover {
    background-image: url(${iconEditFill});
  }
`;

export const Stop = styled.div`
  background-image: url(${iconStop});
  background-size: 27px;
  background-repeat: no-repeat;
  margin: 0px 3px;
  width: 27px;
  height: 27px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

export const NewDocument = styled.div`
  margin: 0px 3px;
  background-repeat: no-repeat;
  background-image: url(${iconNewDocumentOutline});
  width: 27px;
  height: 27px;
  cursor: pointer;
  :hover {
    background-image: url(${iconNewDocument});
  }
`;
export const Person = styled.div`
  margin: 0px 3px;
  background-image: url(${iconPersonOutline});
  width: 26px;
  height: 26px;
  cursor: pointer;
  :hover {
    background-image: url(${iconPerson});
  }
`;

export const Wait = styled.div`
  margin: 0px 3px;
  background-image: url(${iconWaitOutline});
  width: 26px;
  background-repeat: no-repeat;
  height: 26px;
  cursor: pointer;
  :hover {
    background-image: url(${iconWait});
  }
`;

export const ShareEmail = styled.div`
  margin: 0px 3px;
  background-repeat: no-repeat;
  background-image: url(${iconShareEmailOutline});
  width: 27px;
  height: 27px;
  cursor: pointer;
  :hover {
    background-image: url(${iconShareEmail});
  }
`;

export const Download = styled.div`
  margin: 0px 3px;
  background-repeat: no-repeat;
  background-image: url(${iconDownloadOutline});
  width: 27px;
  height: 27px;
  cursor: pointer;
  :hover {
    background-image: url(${iconDownload});
  }
`;
export const Home = styled.div`
  margin: 0px 3px;
  background-repeat: no-repeat;
  background-image: url(${iconHomeOutline});
  width: 27px;
  height: 27px;
  cursor: pointer;
  :hover {
    background-image: url(${iconHome});
  }
`;

export const Start = styled.div`
  margin: 0px 3px;
  background-image: url(${iconStartOutline});
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
  cursor: pointer;
  :hover {
    background-image: url(${iconStart});
  }
`;

export const Dashboard = styled.div`
  margin: 0px 3px;
  background-image: url(${IconDashboard});
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
  cursor: pointer;
`;

export const CopyLink = styled.div`
  margin: 0px 3px;
  background-image: url(${IconCopyLink});
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
  cursor: pointer;
  :hover {
    background-image: url(${IconCopyLinkHover});
  }
`;

export const SendWhats = styled.div`
  margin: 0px 3px;
  background-image: url(${IconSendWhats});
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
  cursor: pointer;
  :hover {
    background-image: url(${IconHoverWhats});
  }
`;
