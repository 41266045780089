/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { useSelector } from 'react-redux';

import {
  IconCrossBlue,
  IconCrossRed,
  IconNotSignedDocument,
  IconSignedDocuments,
} from '~/presentation/base/icons';
import { More } from '~/presentation/components/UI/popover';
import { iClinicalDocsResults, iStore } from '~/domain/interfaces/models';
import { iUser } from '~/presentation/pages/prescriptionDetail/interface';
import {
  Content,
  HeaderContent,
  BodyColumnLeft,
  BodyColumnRight,
  CreatedAt,
  Patient,
  Signed,
  Title,
  Body,
  DateStyle,
  Moree,
  HeaderDivision,
} from './styles/StyledHeader';

import { translator } from '../i18n';

interface HeaderProps {
  name?: string;
  sent?: string;
  isSigned: boolean;
  type?: string;
  hideMoreOptions?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  name,
  sent,
  isSigned,
  type = 'Receita Especial',
  hideMoreOptions = false,
}) => {
  const { role } = useSelector((states: iStore) => states.auth.selectUser);

  const formattedDate = (date: string, dateType: 'date' | 'date-hour') => {
    const dateJavascript = new Date(date);

    if (!(dateJavascript instanceof Date && !Number.isNaN(dateJavascript)))
      return date;

    return dateType === 'date-hour'
      ? `${dateJavascript.toLocaleDateString('pt-br')} ${translator(
          'às'
        )} ${dateJavascript.toLocaleTimeString('pt-br')}`
      : `${dateJavascript.toLocaleDateString('pt-br')}`;
  };

  return (
    <Content isOk={isSigned}>
      <HeaderContent>
        <HeaderDivision>
          <Title id="document_type" isOk={isSigned}>
            {isSigned ? <IconCrossBlue /> : <IconCrossRed />}
            {type}
          </Title>
        </HeaderDivision>
        <Moree>
          {hideMoreOptions || (!isSigned && role === 'CON') ? null : (
            <More content={<div />} positionY="top" positionX="right" />
          )}
        </Moree>
      </HeaderContent>
      <Body>
        <BodyColumnLeft>
          <Patient>
            {`${translator('Paciente')}:`}
            <div className="patient-name">{name}</div>
          </Patient>
          <CreatedAt>
            {`${translator('Criado em')}:${
              sent ? formattedDate(sent, 'date-hour') : '03/11/2020 às 10:00'
            }`}
          </CreatedAt>
        </BodyColumnLeft>
        <BodyColumnRight>
          <Signed isOk={isSigned}>
            {isSigned ? <IconSignedDocuments /> : <IconNotSignedDocument />}
            {isSigned ? translator('Assinado') : translator('Pendente')}
          </Signed>
          <DateStyle isOk={isSigned}>
            {isSigned
              ? `${translator('Enviado ao paciente:')} ${
                  sent ? formattedDate(sent, 'date') : '03/11/2020 às 10:00'
                }`
              : `${translator('Documento não enviado')}`}
          </DateStyle>
        </BodyColumnRight>
      </Body>
    </Content>
  );
};

export default Header;
