import { takeLatest } from 'redux-saga/effects';
import { ClinicalDocsTypes } from '~/domain/interfaces/redux/clinicalDocs/types';
import {
  onCreateByEmail,
  onCreateByEmailFailed,
  onCreateByEmailSuccess,
} from './CreateByEmail';
import {
  onGetByDownload,
  onGetDownloadByIdFailed,
  onGetDownloadByIdSuccess,
} from './GetByDownload';
import { onGetById, onGetByIdFailed, onGetByIdSuccess } from './GetById';
import {
  onUpdateSign,
  onUpdateSignFailed,
  onUpdateSignSuccess,
} from './UpdateBySign';
import { onDeleteRequest, onDeleteSuccess, onDeleteFailed } from './Delete';
import { onGetAll, onGetAllFailed, onGetAllSuccess } from './GetAll';
import {
  onGetByCode,
  onGetByCodeFailed,
  onGetByCodeSuccess,
} from './GetByCode';
import {
  onDownloadByCode,
  onDownloadByCodeFailed,
  onDownloadByCodeSuccess,
} from './DownloadByCode';
import { onSelectOne } from './SelectOne';

const tasks = [
  takeLatest(ClinicalDocsTypes.CREATE_BY_EMAIL, onCreateByEmail),
  takeLatest(ClinicalDocsTypes.CREATE_BY_EMAIL_SUCESS, onCreateByEmailSuccess),
  takeLatest(ClinicalDocsTypes.CREATE_BY_EMAIL_FAILED, onCreateByEmailFailed),

  takeLatest(ClinicalDocsTypes.DELETE, onDeleteRequest),
  takeLatest(ClinicalDocsTypes.DELETE_SUCCESS, onDeleteSuccess),
  takeLatest(ClinicalDocsTypes.DELETE_FAILED, onDeleteFailed),

  takeLatest(ClinicalDocsTypes.GETALL, onGetAll),
  takeLatest(ClinicalDocsTypes.GETALL_SUCCESS, onGetAllSuccess),
  takeLatest(ClinicalDocsTypes.GETALL_FAILED, onGetAllFailed),

  takeLatest(ClinicalDocsTypes.GET_BY_DOWNLOAD, onGetByDownload),
  takeLatest(
    ClinicalDocsTypes.GET_BY_DOWNLOAD_SUCESS,
    onGetDownloadByIdSuccess
  ),
  takeLatest(ClinicalDocsTypes.GET_BY_DOWNLOAD_FAILED, onGetDownloadByIdFailed),

  takeLatest(ClinicalDocsTypes.GET_BY_ID, onGetById),
  takeLatest(ClinicalDocsTypes.GET_BY_ID_SUCCESS, onGetByIdSuccess),
  takeLatest(ClinicalDocsTypes.GET_BY_ID_FAILED, onGetByIdFailed),

  takeLatest(ClinicalDocsTypes.UPDATE_BY_SIGN, onUpdateSign),
  takeLatest(ClinicalDocsTypes.UPDATE_BY_SIGN_SUCESS, onUpdateSignSuccess),
  takeLatest(ClinicalDocsTypes.UPDATE_BY_SIGN_FAILED, onUpdateSignFailed),

  takeLatest(ClinicalDocsTypes.GET_BY_CODE, onGetByCode),
  takeLatest(ClinicalDocsTypes.GET_BY_CODE_SUCCESS, onGetByCodeSuccess),
  takeLatest(ClinicalDocsTypes.GET_BY_CODE_FAILED, onGetByCodeFailed),

  takeLatest(ClinicalDocsTypes.DOWNLOAD_BY_CODE, onDownloadByCode),
  takeLatest(
    ClinicalDocsTypes.DOWNLOAD_BY_CODE_SUCCESS,
    onDownloadByCodeSuccess
  ),
  takeLatest(ClinicalDocsTypes.DOWNLOAD_BY_CODE_FAILED, onDownloadByCodeFailed),

  takeLatest(ClinicalDocsTypes.SELECT_ONE, onSelectOne),
];

export default tasks;
