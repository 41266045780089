/* eslint-disable react/require-default-props */
import React, { useCallback } from 'react';

import Header from '~/presentation/components/clinicalDocumentViewer/Header';
import { PharmacyIcon } from '~/presentation/base/icons';
import { Menu } from '~/presentation/components/menu';
import { translator } from '~/presentation/components/i18n';
import { Button } from '~/presentation/components/UI';
import { makeRemoteDownloadClinicalDocsByCode } from '~/main/factories/usecases/clinicalDocs/DownloadClinicalDocsByCode';
import { StickyHeadTable } from '~/presentation/components/Table';
import { GetClinicalDocsByCode } from '~/domain/usecases/clinicalDocs/remote';
import {
  Container,
  NavContent,
  TextAreaDoc,
  ContainerButtons,
  RowContainer,
  TitleFarmacia,
  Title,
} from './StyledPrescription';

interface ownProps {
  doc: GetClinicalDocsByCode.Model;
  code?: string;
  secret?: string;
  handleBack?: () => void;
}

interface Map {
  [key: string]: string | undefined;
}

const PrescriptionDetail: React.FC<ownProps> = ({
  handleBack,
  code,
  secret,
  doc,
}): JSX.Element => {
  const columns: string[] = [
    translator('Droga'),
    translator('Posologia'),
    translator('Dosagem'),
  ];

  const typeDoc: Map = {
    BASIC: translator('Receita simples'),
    MEDICALCERTIFICATE: translator('Atestado Médico'),
    EXAMREQUEST: translator('Requisição de exames'),
  };

  const handleDownload = useCallback(() => {
    makeRemoteDownloadClinicalDocsByCode()
      .get({ code, secret })
      .then((res) => {
        const urlToDownload = window.URL.createObjectURL(res as Blob);

        const link = document.createElement('a');

        link.href = urlToDownload;
        link.target = '_blank';
        link.click();
      })
      .catch((err) => {
        console.log('err: ', err);
      });
  }, [code, secret]);

  return (
    <Container>
      <Menu isPrescription handleBack={handleBack} />
      <TitleFarmacia>
        <PharmacyIcon />
        <Title>{`${translator('Acesso para farmácia')}`}</Title>
      </TitleFarmacia>
      <NavContent>
        <Header
          name={`${doc?.patient?.firstName} ${doc?.patient?.lastName}`}
          type={doc ? typeDoc[doc?.type?.name] : undefined}
          sent={doc?.sent}
          isSigned
          hideMoreOptions
        />
        {doc?.type?.id === 1 && (
          <StickyHeadTable
            columns={columns}
            rows={doc?.content?.drugs ?? []}
            bold={['drug', 'dosage']}
          />
        )}
        {doc?.type?.id === 2 && (
          <TextAreaDoc>
            {translator('Atesto que o(a) Sr(a).')}
            <strong>{`${doc?.patient?.firstName} ${doc?.patient?.lastName} `}</strong>
            {translator(
              'encontra-se sob meus cuidados profissionais necessitando de afastamento de suas atividades pelo período de'
            )}
            {` ${doc?.content?.periodo}.`}
            <div id="obs" className="observacoes">
              {translator('Observações:')}
            </div>
            {`${doc?.content?.observacoes}`}
          </TextAreaDoc>
        )}
        {doc?.type?.id === 3 && (
          <TextAreaDoc>
            <strong>{translator('Indicação clínica:')}</strong>
            {` ${doc?.content?.indicacaoClinica}`}
            <div id="obs" className="observacoes">
              {translator('Solicito:')}
            </div>
            {translator('Requisito o exame')}
            {` ${
              doc?.content?.solicitacao && doc?.content?.solicitacao?.[0]?.exam
            } `}
            {translator('para o paciente pois o mesmo está com sintomas.')}
          </TextAreaDoc>
        )}
      </NavContent>
      <ContainerButtons>
        <Button
          color="#ec5c52"
          size="medium"
          variant="secundary"
          onClick={() => handleBack?.()}
        >
          {`${translator('Sair')}`}
        </Button>
        <RowContainer>
          <Button
            size="medium"
            variant="secundary"
            onClick={(e) => {
              e.preventDefault();
              window.open('https://assinaturadigital.iti.gov.br/');
            }}
          >
            {`${translator('Validador ICP Brasil')}`}
          </Button>
          <Button
            size="medium"
            onClick={(e) => {
              e.preventDefault();
              handleDownload();
            }}
          >
            {`${translator('Baixar PDF')}`}
          </Button>
        </RowContainer>
      </ContainerButtons>
    </Container>
  );
};

export default PrescriptionDetail;
