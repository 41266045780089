import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache
);

export const HandlerEvents = async (data: any): Promise<void> => {
  const { auth } = JSON.parse(localStorage.getItem('persist:reducers') || '');
  const { info } = JSON.parse(auth);

  console.log('>>> Notification listener: ', data);

  if (info.user === undefined) return;

  console.log('atualizando notificações handler: ', info.user.id);

  switch (data?.notification?.name) {
    default:
      break;
  }
};
