/* eslint-disable no-nested-ternary */
import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { iMenuNotification } from '~/domain/interfaces/components/menu';
import { iStore } from '~/domain/interfaces/models';
import { CalendarWhite } from '~/presentation/base/icons';
import { translator } from '../../i18n';
import { iNotificationStyled } from './interface';
import { MapNotificationsData } from './mapper/map';
import NotificationSkeleton from './skeleton/NotificationSkeleton';
import {
  Action,
  Body,
  Container,
  Description,
  Header,
  Icon,
  Infos,
  Item,
  More,
  Name,
  NotificationDate,
  Title,
} from './styles/StyledNotification';

const Notification: React.FC = () => {
  const { notifications } = useSelector((store: iStore) => store);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const colorIcon: iNotificationStyled = {
    APPOINTMENT_CREATED: 'primaryDark',
    APPOINTMENT_CANCELLED: 'statusCancel',
    APPOINTMENT_CONFIRMED: 'statusSucess',
    APPOINTMENT_DELETED: 'statusCancel',
    APPOINTMENT_SCHEDULED: 'statusScheduled',
    APPOINTMENT_UPDATED: 'statusScheduledBack',
    APPOINTMENT_PROFESSIONAL_COMPANION: 'primary',
    WAITINGROOM_JOINED: 'statusProgress',
    WAITINGROOM_LEFT: 'statusCancelBack',
    NEW_CHATMESSAGE: 'statusYes',
    // * Novas notificações
    CHAT_LEFT: 'primary',
    CHAT_FINISHED: 'statusCancel',
    CREATED_ATTACHED: 'statusSucess',
    CREATED_DETACHED: 'statusSucess',
    SIGNED: 'statusSucess',
  };
  const action = {
    APPOINTMENT_CREATED: ` ${translator('criou um atendimento')}`,
    APPOINTMENT_CANCELLED: ` ${translator('cancelou um atendimento')}`,
    APPOINTMENT_CONFIRMED: ` ${translator('confirmou o atendimento')}`,
    APPOINTMENT_DELETED: ` ${translator('deletou um atendimento')}`,
    APPOINTMENT_SCHEDULED: ` ${translator('agendou o atendimento')}`,
    APPOINTMENT_UPDATED: ` ${translator('atualizou um atendimento')}`,
    APPOINTMENT_PROFESSIONAL_COMPANION: ` ${translator(
      'criou um atendimento com profissional acompanhante'
    )}`,
    WAITINGROOM_JOINED: ` ${translator('entrou na sala de espera')}`,
    WAITINGROOM_LEFT: ` ${translator('saiu da sala de espera')}`,
    NEW_CHATMESSAGE: ` ${translator('Nova mensagem')}`,
    // * Novas notificações
    CHAT_LEFT: 'saiu do chat',
    CHAT_FINISHED: 'chat finalizado',
    CREATED_ATTACHED: 'vinculou um documento clínico a uma consulta',
    CREATED_DETACHED: 'criou um documento clinico avulso',
    SIGNED: 'documento assinado com sucesso',
  };

  const [noti, setNoti] = useState<iMenuNotification[]>([]);

  const notificationsData = MapNotificationsData(notifications);

  const STEP = 20;

  useEffect(() => {
    const notificationData = MapNotificationsData(notifications).reverse();

    setNoti(notificationData.slice(0, STEP));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  function handleClear() {
    setNoti([]);
  }

  function showMore() {
    setLoading(true);
    fetchMoreData();
  }

  const fetchMoreData = () => {
    if (noti.length >= notificationsData.length) {
      setHasMore(false);
    }

    setTimeout(() => {
      const notificationData = MapNotificationsData(notifications).reverse();
      setNoti(notificationData.slice(0, noti.length + STEP));
      setLoading(false);
    }, 1500);
  };

  return (
    <Container>
      <Header>
        {translator('Notificações')}
        {/* <IconCloseButtonModal /> */}
      </Header>
      {/* TODO: Solução temporária, nova implementação de notificações está sendo planejada */}
      {/* <Links>
        <button type="button" onClick={handleClear}>
          {translator('Ocultar notificações')}
        </button>
        <button type="button" onClick={handleClear}>
          {translator('Limpar todas')}
        </button>
      </Links> */}
      <Body>
        <InfiniteScroll
          dataLength={notificationsData.length}
          hasMore={hasMore}
          next={fetchMoreData}
          loader={<></>}
        >
          {!noti && notifications.loading ? (
            <NotificationSkeleton />
          ) : (
            noti.map((notify) => (
              <Item key={notify.id}>
                <Icon color={colorIcon[notify.notification?.name]}>
                  <CalendarWhite />
                </Icon>
                <Infos>
                  <Title>
                    <Name>
                      {`${
                        notify.message.professional?.name
                          ? notify.message.professional?.name
                          : notify.message.from?.name ??
                            `${notify.message.from?.firstName} ${notify.message.from?.lastName}`
                      } `}
                      {}
                    </Name>
                    <Action>{action[notify?.notification?.name]}</Action>
                  </Title>
                  <Description>
                    <NotificationDate>
                      {notify.message.clinicalDoc?.signed
                        ? new Date(
                            notify.message.clinicalDoc?.signed
                          ).toLocaleString()
                        : notify.notification.name.includes('TACHED')
                        ? new Date(
                            notify.message.data?.createdAt
                          ).toLocaleString()
                        : new Date(
                            notify.message.data?.scheduled
                          ).toLocaleString()}
                    </NotificationDate>
                  </Description>
                </Infos>
              </Item>
            ))
          )}
        </InfiniteScroll>
        {hasMore ? (
          <More>
            {loading ? (
              <NotificationSkeleton />
            ) : (
              <button type="button" onClick={showMore}>
                Mostrar mais
              </button>
            )}
          </More>
        ) : null}
      </Body>
    </Container>
  );
};

export default Notification;
