import styled from 'styled-components';
import { shade } from 'polished';

export const Link = styled.a`
  font-size: 12px;
  color: ${props => props.theme.primary};
  cursor: pointer;
  :hover {
    color: ${shade(0.25, '#0000ff')};
  }
`;
