import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { Description, Infos, Item, Title } from '../styles/StyledNotification';

const NotificationSkeleton: React.FC = () => {
  const cont = [1, 2, 3, 4, 5];
  return (
    <>
      {cont.map((item) => (
        <Item key={item}>
          <Skeleton
            animation="wave"
            variant="circle"
            width="32px"
            height="32px"
          />
          <Infos>
            <Title>
              <Skeleton animation="wave" variant="text" width="230px" />
            </Title>
            <Description>
              <Skeleton
                animation="wave"
                variant="rect"
                width="200px"
                height="10px"
              />
            </Description>
          </Infos>
        </Item>
      ))}
    </>
  );
};

export default NotificationSkeleton;
