import { DownloadClinicalDocsByCode } from '~/domain/usecases/clinicalDocs/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteDownloadClinicalDocsByCode
  implements DownloadClinicalDocsByCode
{
  private readonly url: string;

  private readonly httpClient: HttpClient<DownloadClinicalDocsByCode.Model>;

  constructor(
    url: string,
    httClient: HttpClient<DownloadClinicalDocsByCode.Model>
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  get = async (
    params: DownloadClinicalDocsByCode.Params
  ): Promise<DownloadClinicalDocsByCode.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.code}/drugstore/download?secret=${params.secret}`,
      method: 'get',
      headers: { 'Content-Type': 'application/octet-stream' },
      responseType: 'blob',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
