import axios, { AxiosResponse } from 'axios';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import storeDev from '~/data/store';
import { iStore } from '~/domain/interfaces/models';
import { translator } from '~/presentation/components/i18n';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
} from '../../../data/protocols/http';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache
);

class AxiosHttpClient implements HttpClient {
  /**
   * Request for external API.
   * @param data data to make the request.
   */
  public request = async (data: HttpRequest): Promise<HttpResponse> => {
    let axiosResponse: AxiosResponse;
    const store: iStore = storeDev.getState();

    const { token } = store.auth.access;

    let authorizationHeader = data.headers;

    if (
      token &&
      (!data.headers ||
        (typeof data.headers === 'object' && Object.keys(data.headers).length))
    ) {
      authorizationHeader = {
        Authorization: `Bearer ${token}`,
        ...data.headers,
      };
    }

    try {
      axiosResponse = await axios.request({
        url: data.url,
        method: data.method,
        data: data.body,
        headers: authorizationHeader,
        responseType: data.responseType,
        params: data.params,
      });
    } catch (error: any) {
      if (error.response?.status === 502 || error.response?.status === 503) {
        let found = false;

        document.querySelectorAll('.notification__message').forEach((node) => {
          if (
            node.innerHTML.includes('Falha interna no servidor') ||
            node.innerHTML.includes('Internal server failure') ||
            node.innerHTML.includes('Fallo interno del servidor')
          ) {
            found = true;
          }
        });

        if (!found) {
          AlertMessage({
            message: intl.formatMessage({
              id: 'Falha interna no servidor, tente novamente em minutos.',
            }),
            type: 'danger',
          });
        }
      }
      axiosResponse = error.response;
    }

    return {
      statusCode: axiosResponse?.status,
      body: axiosResponse?.data,
    };
  };
}

export default AxiosHttpClient;
