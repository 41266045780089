import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetClinicalDocsByCode as UsecaseRemoteGetClinicalDocsByCode } from '~/domain/usecases/clinicalDocs/remote';
import { GetClinicalDocsByCode as UsecaseReduxGetClinicalDocsByCode } from '~/domain/usecases/clinicalDocs/redux';

import { RemoteGetClinicalDocsByCode } from '~/data/repository/clinicalDocs';
import { ReduxGetClinicalDocsByCode } from '~/data/store/reducer/clinicalDocs/usecases';

export const makeRemoteGetClinicalDocsByCode =
  (): UsecaseRemoteGetClinicalDocsByCode =>
    new RemoteGetClinicalDocsByCode(
      makeApiUrl('/clinicalDocs'),
      makeHttpClient(),
    );

export const makeReduxGetClinicalDocsByCode =
  (): UsecaseReduxGetClinicalDocsByCode => new ReduxGetClinicalDocsByCode();
