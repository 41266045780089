import { iConsultant } from '~/domain/interfaces/models/Consultant';
import { ConsultantTypes } from '~/domain/interfaces/redux/Consultant/types';
import { AuthTypes } from '~/domain/interfaces/redux/auth/types';
import { ConsultantActions } from './actions';

export const initialState: iConsultant = {
  loading: false,
  pageSize: 0,
  totalItems: 0,
  results: [],
};

const reducer = (
  state = initialState,
  action: ConsultantActions
): iConsultant => {
  switch (action.type) {
    case ConsultantTypes.CREATE:
      return { ...state, loading: true };
    case ConsultantTypes.CREATE_SUCCESS:
      return { ...state, loading: false };
    case ConsultantTypes.CREATE_FAILED:
      return { ...state, loading: false };
    case ConsultantTypes.GETALL:
      return { ...state, loading: true };
    case ConsultantTypes.GETALL_SUCCESS:
      return {
        ...state,
        pageSize: action.payload.pageSize,
        totalItems: action.payload.totalItems,
        results: action.payload.results,
        loading: false,
      };
    case AuthTypes.LOGOUT:
      return initialState;
    // case ConsultantTypes.CREATE:
    //   return state;
    // case ConsultantTypes.CREATE_FAILED:
    //   return state;
    // case ConsultantTypes.CREATE_SUCCESS: {
    //   return state;
    // }
    default:
      return state;
  }
};

export default reducer;
