import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.background};
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const TitleFarmacia = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 1rem 0;

  gap: 10px;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #004ba5;
`;

export const NavContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 78rem;
  background: white;
  row-gap: 30px;
  border: 1px solid #efefef;
  border-radius: 8px;

  #certificate-div {
    width: 50%;
    min-width: 30em;
    display: flex;
    justify-content: center;
    align-items: center;

    .option {
      font-weight: bold;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const TextAreaDoc = styled.div`
  width: 100%;
  height: 50vh;
  padding: 10px;
  border-radius: 2px;

  .observacoes {
    margin-top: 25px;
    font-size: 16px;
    font-weight: bold;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 78rem;

  padding-top: 25px;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 16px;
`;
