import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';

import { UpdateClinicalDocsBySign } from '~/domain/usecases/clinicalDocs/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
  Forbidden,
  BadGateway,
  UnProcessableEntity,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { translator } from '~/presentation/components/i18n';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export class RemoteUpdateClinicalDocsBySign
  implements UpdateClinicalDocsBySign
{
  private readonly url: string;

  private readonly httpClient: HttpClient<UpdateClinicalDocsBySign.Model>;

  constructor(
    url: string,
    httClient: HttpClient<UpdateClinicalDocsBySign.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  patchBySign = async (
    params: UpdateClinicalDocsBySign.Params,
  ): Promise<UpdateClinicalDocsBySign.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.docId}/sign`,
      method: 'patch',
      body: {
        signatureValue: params.signatureValue,
      },
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      case HttpStatusCode.unprocessableEntity:
        throw new UnProcessableEntity();
      default:
        throw new UnexpectedError();
    }
  };
}
