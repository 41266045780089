import styled, { css } from 'styled-components';

import { withAccess } from '~/presentation/hooks/access';

interface iconProps {
  icon?: boolean;
}

interface ownProps {
  isPrescription?: boolean;
}

export const Container = styled.div<ownProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.primary};
  width: 100%;
  padding: ${({ isPrescription }) =>
    isPrescription ? '10px 15px 10px 15px' : '0'};

  #logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    margin-left: 30px;
    width: 250px;
    padding-top: 6px;
  }
`;

export const LeftItem = styled.div`
  max-width: 1fr;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const RightItem = styled.div`
  max-width: 1fr;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 40px;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;

export const LeftText = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;

  cursor: pointer;
`;

export const Item = withAccess(styled.button`
  padding: 15px 10px;
  font-size: 15px;
  background: transparent;
  cursor: pointer;
  border: none;
  color: white;

  :hover {
    background: white;
    color: #004ba5;
    transition: background 0.5s;
  }
`);

export const Image = styled.img``;

const Options = css<iconProps>`
  height: 100%;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  font-size: 18px;
  color: white;

  svg {
    margin-left: ${props => (props.icon ? '10px' : '0px')};
  }

  :hover {
    background: ${props => props.theme.primaryDark};
  }
`;

export const Schedule = withAccess(styled.div`
  ${Options}
`);
export const Patient = withAccess(styled.div`
  ${Options}
`);
export const ClinicalDocs = withAccess(styled.div`
  ${Options}
`);

export const Users = withAccess(styled.div`
  ${Options}
  svg {
    color: white;
  }
`);

export const Adm = withAccess(styled.div`
  ${Options}
`);

export const Drop = withAccess(styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  color: white;
  background: ${props => props.theme.primaryDark};
  cursor: pointer;
  border: none;

  :hover {
    background: ${props => props.theme.primaryLight};
  }
`);

export const UserOptions = styled.div`
  button {
    width: 100%;
    border: none;
    font-size: 16px;
  }
`;
