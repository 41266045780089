import { iActionGetClinicalDocsByCode } from '~/domain/interfaces/redux/clinicalDocs/getByCode';

export function* onGetByCode(action: iActionGetClinicalDocsByCode): Generator {
  yield 10;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetByCodeSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetByCodeFailed() {}
