import { put, call } from 'redux-saga/effects';
import { useSelector } from 'react-redux';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import {
  deleteSucess,
  deleteFailed,
} from '~/data/store/reducer/clinicalDocs/actions/delete';

import { iActionDelete } from '~/domain/interfaces/redux/clinicalDocs/delete';
import {
  DeleteClinicalDocs,
  GetAllClinicalDocs,
} from '~/domain/usecases/clinicalDocs/remote';
import { makeRemoteDeleteClinicalDocs } from '~/main/factories/usecases/clinicalDocs/DeleteClinicalDocsFactory';

import { getAllRequest } from '~/data/store/reducer/clinicalDocs/actions/getAll';

import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { iStore } from '~/domain/interfaces/models';
import { History } from '~/main/routes';
import { translator } from '~/presentation/components/i18n';
import storeDev from '../..';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache
);

export function* onDeleteRequest(action: iActionDelete) {
  // const ok = false;
  const remoteDeleteClinicalDocs = makeRemoteDeleteClinicalDocs();

  try {
    const response: DeleteClinicalDocs.Model = yield call(
      remoteDeleteClinicalDocs.delete,
      action.payload
    );

    if (action.payload.handleDelete) action.payload.handleDelete();
    yield put(deleteSucess());
  } catch (e) {
    yield put(deleteFailed());
  }
}

export function* onDeleteSuccess() {
  const store: iStore = storeDev.getState();

  const { selectUser, info } = store.auth;

  let filter: GetAllClinicalDocs.Params['filter'] = {
    org: selectUser.orgId,
    enabled: true,
  };

  const professionalId = info.professionals?.find(
    (item) => item.orgUnit.id === selectUser.orgUnitId
  )?.id;

  if (selectUser.role === 'PRO' && info.professionals?.length) {
    filter = { ...filter, professional: professionalId };
  }

  switch (window.location.pathname) {
    case '/appointment/detail':
    case '/clinicaldocs':
    case '/conf':
      break;

    default:
      History.getHistory().push('/clinicalDocs');
      break;
  }

  // if(window.location.pathname.includes('/clinicalDocs/'))
  yield put(getAllRequest({ filter }));
}

export function onDeleteFailed() {
  AlertMessage({
    type: 'danger',
    message: intl.formatMessage({ id: 'Falha ao remover documento clínico.' }),
  });
}
