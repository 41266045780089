/* eslint-disable react/jsx-no-bind */
import React from 'react';

import { NotFound } from '~/presentation/base/icons';
import { Button } from '~/presentation/components/UI';
import { History } from '~/main/routes';
import { translator } from '~/presentation/components/i18n';
import {
  Container,
  Message,
  Title,
  SubTitle,
} from './styles/StyledPageNotFound';

const PageNotFound: React.FC = () => {
  function goBack() {
    History.back();
  }

  return (
    <Container>
      <Title>Ops...</Title>
      <SubTitle>404 Not Found</SubTitle>
      <NotFound width="400px" height="400px" />
      <Message>
        A página que você procura não existe. Clique no botão abaixo para
        retornar!
      </Message>
      <Button
        variant="secundary"
        size="large"
        height="45px"
        onClick={goBack}
        rounded
        autoFocus
      >
        {translator('Voltar')}
      </Button>
    </Container>
  );
};

export default PageNotFound;
