import React from 'react';
import { combineReducers } from 'redux';
import { persistReducer, createTransform } from 'redux-persist';
import omit from 'lodash/omit';
import storage from 'redux-persist/lib/storage';
// import { initialState } from '~/data/store/reducer/auth';
import menu from './menu';
import auth from './auth';
import message from './message';
import clinicalDocs from './clinicalDocs';
import consultant from './Consultant';

const reducer = combineReducers({
  menu,
  auth,
  message,
  clinicalDocs,
  consultant,
});

// export type RootState = ReturnType<typeof rootReducer>;

// const store = createStore(reducer);

// export const rootReducer: React.FC<iLogout> = ({
//   logoutApplication,
//   state,
// }) => {
//   if (logoutApplication.type === '@auth/LOGOUT') {

//   }
//   return reducer(state, logoutApplication);
// };

// Blacklist specific property to not persist on LocalStorage
const blacklistProperty = (blacklistPaths: Array<string>) => {
  return createTransform((inboundState: any, key) => {
    const blacklistPathsForKey = blacklistPaths
      .filter((path) => path.startsWith(`${String(key)}.`))
      .map((path) => path.substr(String(key).length + 1));

    return omit(inboundState, ...blacklistPathsForKey);
  }, null);
};

const authPersistConfig = {
  key: 'reducers',
  storage,
  blacklist: ['message', 'showModal'],
  transforms: [
    blacklistProperty([
      'auth.loading',
      // 'appointment.date',
      'appointment.loading',
      'chat.loading',
      'clinicalDocs.loading',
      'notifications.loading',
      'permissions.loading',
      'professions.loading',
      'specialty.loading',
      'supportDoc.loading',
      'users.loading',
    ]),
  ],
};

export default persistReducer(authPersistConfig, reducer);
