import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { DownloadClinicalDocsByCode as UsecaseRemoteDownloadClinicalDocsByCode } from '~/domain/usecases/clinicalDocs/remote';
import { DownloadClinicalDocsByCode as UsecaseReduxDownloadClinicalDocsByCode } from '~/domain/usecases/clinicalDocs/redux';

import { RemoteDownloadClinicalDocsByCode } from '~/data/repository/clinicalDocs';
import { ReduxDownloadClinicalDocsByCode } from '~/data/store/reducer/clinicalDocs/usecases';

export const makeRemoteDownloadClinicalDocsByCode =
  (): UsecaseRemoteDownloadClinicalDocsByCode =>
    new RemoteDownloadClinicalDocsByCode(
      makeApiUrl('/clinicalDocs'),
      makeHttpClient(),
    );

export const makeReduxDownloadClinicalDocsByCode =
  (): UsecaseReduxDownloadClinicalDocsByCode =>
    new ReduxDownloadClinicalDocsByCode();
