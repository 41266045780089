import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${props => props.theme.background};
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Title = styled.h1`
  font-size: 25px;
  font-weight: 700;
  line-height: 64px;
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100vw;
  height: 100%;

  .foot {
    margin-top: 40px;
  }
`;

export const Subtitle = styled.p`
  width: 420px;
  text-align: center;
  text-align-last: center;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 10px;
`;

export const Division = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'logo input';

  .cellLogo {
    grid-area: 'logo';
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 50px;
  }

  .enter {
    grid-area: 'input';
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 40px;
  }

  .input-recipe {
    width: 95%;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  margin: 20px;
  gap: 20px;

  .links {
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 12px;
  }
`;

export const FooterImgs = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  margin-right: 2px;
`;
