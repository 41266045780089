/* eslint-disable react/require-default-props */
import React, { useState, forwardRef } from 'react';
import { IconErrorInput, IconV4Hlogo } from '~/presentation/base/icons';

import { Container, Label, InputContainer, Message } from './styles';

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name?: string;
  label?: string;
  message?: string;
  width?: string;
  height?: string;
  error?: boolean;
  required?: boolean;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  register?: () => any;
  gridArea?: string;
}

const Input: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    label,
    message,
    error = false,
    width = '100%',
    height = '48px',
    required = false,
    icon,
    // value,
    register,
    gridArea,
    ...rest
  },
  ref
) => {
  const [focus, setFocus] = useState<boolean>(false);

  const mTop = label ? '4px' : '0px';
  const mBottom = message ? '2px' : '0px';
  const { disabled } = rest;

  const Logo = icon || IconV4Hlogo;

  // if the component are use react-hook-form.
  if (register) register();

  let inputLabel = label;
  if (required) inputLabel = `${label}*`;

  return (
    <Container width={width} gridArea={gridArea}>
      {label && <Label>{inputLabel}</Label>}
      <InputContainer
        style={{ marginTop: mTop, marginBottom: mBottom }}
        height={height}
        focus={focus}
        disabled={disabled}
        error={error}
      >
        <input {...rest} ref={ref} />
        {icon && <Logo className="icon" />}
        {error && <IconErrorInput className="icon" />}
      </InputContainer>
      <Message error={error}>{message}</Message>
    </Container>
  );
};

export default forwardRef(Input);
