import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models/Store';

import { ownProps } from '../interface';

/**
 * Mapping of the appointment list data.
 *
 * @param Component component to connect.
 */
export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const message = useSelector((store: iStore) => store.message);

    /* eslint no-param-reassign: "error" */
    Component.defaultProps = {
      message,
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}
